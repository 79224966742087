import api from "./api";
import { API_SURVEYS, API_SURVEYS_BY_COMPANY, API_SURVEYS_STATUS } from "../constants/urls";

/**
 * Servicio para buscar, crear, modificar y eliminar surveys
 * Definición del objecto de surveys
 * @param {object} survey Survey object
 * @param {string} survey.name Nombre de la survey
 * @param {number} survey.companyId Id de la compañía de la survey
 * @param {number} survey.branchId Id de la sucursal de la survey
 * @param {string} survey.channelType Canal de la survey
 * @param {Object[]} survey.steps Objeto de steps de la survey
 * @param {string} survey.steps.stepName Nombre del step de la survey
 * @param {number} survey.steps.stepId Id del step de la survey
 * @param {Object[]} survey.steps.questionList Objeto de questions del step de la survey
 * @param {string} survey.steps.questionList.questionType Tipo de question 
 * @param {string} survey.steps.questionList.answerType Tipo de answer
 * @param {number} survey.steps.questionList.questionIndex Index de la question
 * @param {number} survey.steps.questionList.id Id unico de la question de la survey
 * @param {string} survey.steps.questionList.title Titulo de la question
 * @param {string} survey.steps.questionList.description Descripcion de la question
 * @param {boolean} survey.steps.questionList.obligatory Indicacion de si la question es requerida o no
 * @param {string} survey.steps.questionList.veryDissatisfied Leyenda del valor 1 de la question EMOJIS
 * @param {string} survey.steps.questionList.dissatisfied Leyenda del valor 2 de la question EMOJIS
 * @param {string} survey.steps.questionList.neutral Leyenda del valor 3 de la question EMOJIS
 * @param {string} survey.steps.questionList.satisfied Leyenda del valor 4 de la question EMOJIS
 * @param {string} survey.steps.questionList.verySatisfied Leyenda del valor 5 de la question EMOJIS
 * @param {string} survey.steps.questionList.veryDissatisfied Leyenda del valor 0 de la question EMOJIS
 * @param {string} survey.steps.questionList.initMessage Leyenda del primer valor de la question LINEAR_SCALE
 * @param {string} survey.steps.questionList.endMessage Leyenda del ultimo valor de la question LINEAR_SCALE
 * @param {number} survey.steps.questionList.initVal Primer valor de la question LINEAR_SCALE
 * @param {number} survey.steps.questionList.endVal Ultimo valor de la question LINEAR_SCALE
 * @param {Object[]} survey.steps.questionList.optionList Objeto de options de la question MATRIX
 * @param {number} survey.steps.questionList.optionList.id Id de la opcion de la question MATRIX
 * @param {string} survey.steps.questionList.optionList.image Path de la imagen de la opcion de la question MATRIX
 * @param {string} survey.steps.questionList.optionList.text Texto de la opcion de la question MATRIX
 * @param {string} survey.steps.questionList.optionList.ponderation Ponderacion de la opcion de la question MATRIX
 * @param {number} survey.steps.questionList.optionList.optionIndex Indice de la opcion de la question MATRIX
 * @param {number} survey.steps.questionList.optionList.value Valor de la opcion de la question MATRIX
 * @param {Object[]} survey.steps.questionList.subQuestionList Objeto de subQuestions de la question MATRIX
 * @param {number} survey.steps.questionList.subQuestionList.id Id de la subQuestion de la question MATRIX
 * @param {string} survey.steps.questionList.subQuestionList.title Titulo de la subQuestion de la question MATRIX
 * @param {string} survey.steps.questionList.subQuestionList.description Descripcion de la subQuestion de la question MATRIX
 * @param {string} survey.steps.questionList.subQuestionList.questionType Tipo de la subQuestion de la question MATRIX
 * @param {string} survey.steps.questionList.subQuestionList.answerType Tipo de answer de la subQuestion de la question MATRIX
 * @param {boolean} survey.steps.questionList.subQuestionList.obligatory Indicacion de si la subQuestion es requerida o no, de la question MATRIX
 * @param {number} survey.steps.questionList.subQuestionList.questionIndex Indice de la subQuestion de la question MATRIX
 * @param {Object[]} survey.steps.questionList.optionList Opciones de la question SELECT y/o CHECKBOX
 * @param {string} survey.steps.questionList.optionList.value Valores de una opcion de la question SELECT y/o CHECKBOX
 * @param {string} survey.steps.questionList.optionList.label Label de una opcion de la question SELECT y/o CHECKBOX
 * @param {boolean} survey.steps.questionList.multiple Indicacion de si la question permite seleccion multiple de la question SELECT
 * @returns
 */

export const survey = {    
    getById: async (surveyId, steps) => (await api.get(`${API_SURVEYS}/${surveyId}`,{params:{steps}})).data,
    getByCompanyId: async (id) => (await api.get(`${API_SURVEYS_BY_COMPANY}/${id}`)).data,
    getStatus: async (surveyQueueId) => (await api.get(`${API_SURVEYS}/${API_SURVEYS_STATUS}/${surveyQueueId}?steps=true`)).data,
    create: async (survey) => (await api.post(API_SURVEYS, survey)).data,
    update: async (survey) => (await api.put(`${API_SURVEYS}/${survey.id}`, survey)).data,
    delete: async (id) => (await api.delete(`${API_SURVEYS}/${id}`)).data,
    deleteByCompanyId: async (id) => (await api.delete(`${API_SURVEYS_BY_COMPANY}/${id}`)).data
};
