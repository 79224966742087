import { Box, CardContent, Typography } from "@mui/material";
import { useEffect } from "react";
import { DebQuestionCard } from "../components/styledComponents/DebQuestionCard";
import { enqueueSnackbar } from "notistack";

export default function SurveyExpired() {
    useEffect(() => {
        enqueueSnackbar("La encuesta se encuentra vencida", {
            preventDuplicate: true,
            variant: "error",
        });
    }, []);
    
    return (
    <>
        <Box sx={{  height: "2rem" }} />
        <Box sx={{display: "flex", justifyContent: "center",  alignItems: "center"}} >
            <DebQuestionCard>
                <CardContent>
                    <Box sx={{  height: "2rem" }} />
                    <Typography variant="h4" sx={{display: "flex", justifyContent: "center", alignItems: "center"}} >
                        La encuesta se encuentra vencida
                    </Typography>
                    <Box sx={{  height: "2rem" }} />
                </CardContent>
            </DebQuestionCard>  
        </Box>
        <Box sx={{  height: "2rem" }} />
    </>
    );
};