import { Box, CardContent, Typography } from "@mui/material";
import { useEffect } from "react";
import { DebQuestionCard } from "../components/styledComponents/DebQuestionCard";
import { enqueueSnackbar } from "notistack";

export default function SurveyDisabled() {
    useEffect(() => {
        enqueueSnackbar("La encuesta se encuentra deshabilitada", {
            preventDuplicate: true,
            variant: "error",
        });
    }, []);
    

return (
    <>
        <Box sx={{  height: "2rem" }} />
        <Box sx={{display: "flex", justifyContent: "center",  alignItems: "center"}} >
            <DebQuestionCard>
                <CardContent>
                    <Box sx={{  height: "2rem" }} />
                    <Typography variant="h5" sx={{display: "flex", justifyContent: "center",  alignItems: "center"}} gutterBottom>
                        La encuesta se encuentra deshabilitada
                    </Typography>
                    <Box sx={{  height: "2rem" }} />
                </CardContent>
            </DebQuestionCard>  
        </Box>
        <Box sx={{  height: "2rem" }} />
    </>
    );
};

