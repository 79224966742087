import {useState} from "react";
import {
  TextField,
  Tooltip,
  Typography,
  CardContent,
  useMediaQuery,
  useTheme,
  FormHelperText,
  InputAdornment
} from "@mui/material";
import { DebQuestionCard } from "../styledComponents/DebQuestionCard";
import { useField, useFormikContext } from "formik";


export default function DebSurveyLongQuestion({title, description, obligatory, requiredErrorMsg, ...props}) {
  const { setFieldValue } = useFormikContext();
  const [internalValue, setInternalValue] = useState('');
  const [field, meta] = useField(props);
  const error = meta.touched && meta.error ? true : false;

  const maxLength = 65554;
  
  const handleBlur = () => {
    setFieldValue(props.name, internalValue);
  };

  const handleInput = (event) => {
    setInternalValue(event.target.value.slice(0, maxLength));
  };

  return (
    <DebQuestionCard className="cust-question cust-question-box question-container">
      <span style={{display: 'block'}}>
      <CardContent sx={{ backgroundColor: `${meta.touched && meta.error ? "#ff000017" : "rgba(0, 0, 0, .0)"}` }}>
        <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
          {title}
          {obligatory && (
            <Tooltip
              title={<Typography>Pregunta Requerida</Typography>}
              placement="top"
              arrow>
              <span style={{ color: "red" }}>&nbsp;&nbsp;*</span>
            </Tooltip>
          )}
        </Typography>
        <Typography sx={{ marginBottom: "0.5rem" }}>{description}</Typography>
        <TextField
          id={`deb-survey-short-question-${props.title}`}
          variant="outlined"
          fullWidth
          multiline
          rows={4}
          value={internalValue}
          onBlur={handleBlur}
          onInput={handleInput}
          inputProps={{ maxLength, className: "cust-question cust-question-input" }}
          InputProps={{
            endAdornment: (
              <InputAdornment 
              className="cust-question cust-question-adornment"
              position="end"
              sx={{alignSelf: "flex-end"}}
              >
                {internalValue.length} / {maxLength}
              </InputAdornment>
            ),
            className: "cust-question cust-question-field"
          }}
          {...props}
        />
        <FormHelperText className="cust-question-helper-text" error={error} sx={{ fontSize: "1rem", fontWeight: "bold", mt: "1rem" }}>
          {meta.touched && meta.error ? requiredErrorMsg : " "}
        </FormHelperText>
      </CardContent>
      </span>
    </DebQuestionCard>
  );
};
