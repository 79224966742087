import DebCheckboxQuestion from "../surveyQuestions/DebCheckboxQuestion";
import DebEmojiQuestion from "../surveyQuestions/DebEmojiQuestion";
import DebLinearScaleQuestion from "../surveyQuestions/DebLinearScaleQuestion";
import DebMatrixQuestion from "../surveyQuestions/DebMatrixQuestion";
import DebSelectQuestion from "../surveyQuestions/DebSelectQuestion";
import DebStarQuestion from "../surveyQuestions/DebStarQuestion";
import DebSurveyLongQuestion from "../surveyQuestions/DebSurveyLongQuestion";
import DebSurveyShortQuestion from "../surveyQuestions/DebSurveyShortQuestion";


export function renderQuestions (question) {
    switch (question.questionType) {
      case 'EMOJIS':
        const emojiProps = {
          name: (question.name ?? ''),
          title: (question.title ?? ''),
          description: (question.description ?? ''),
          obligatory: (question.obligatory ?? true),
          requiredErrorMsg: (question.requiredErrorMsg ?? ''),
          optionList: (question.optionList ?? [])
        };
        return <DebEmojiQuestion {...emojiProps} obligatory={emojiProps.obligatory} />;
        case 'LINEAR_SCALE':
          const linearScaleProps = {
            name: (question.name ?? ''),
            title: (question.title ?? ''),
            description: (question.description ?? ''),  
            obligatory: (question.obligatory ?? true),
            requiredErrorMsg: (question.requiredErrorMsg ?? ''),        
            startText: (question.initMessage ?? ''),
            endText: (question.endMessage ?? ''),
            startValue: (question.initVal ?? 0),
            endValue: (question.endVal ?? 0)          
          };
          return <DebLinearScaleQuestion {...linearScaleProps} obligatory={linearScaleProps.obligatory} />;
          case 'STAR':
            const starQuestionProps = {
              name: (question.name ?? ''),
              title: (question.title ?? ''),
              description: (question.description ?? ''),  
              obligatory: (question.obligatory ?? true),
              requiredErrorMsg: (question.requiredErrorMsg ?? ''),        
              startText: (question.initMessage ?? ''),
              endText: (question.endMessage ?? ''),
              startValue: (question.initVal ?? 0),
              endValue: (question.endVal ?? 0)          
            };
            return <DebStarQuestion {...starQuestionProps} obligatory={starQuestionProps.obligatory} />;
      case 'MATRIX':
        const matrixProps = {
          name: (question.name ?? ''),
          title: (question.title ?? ''),
          description: (question.description ?? ''),  
          obligatory: (question.obligatory ?? true),
          requiredErrorMsg: (question.requiredErrorMsg ?? ''),        
          optionList: (question.optionList ?? []),
          subQuestionList: (question.subQuestionList ?? [])       
        };
        return <DebMatrixQuestion {...matrixProps} obligatory={matrixProps.obligatory} />;
      case 'SELECT':
        const selectProps = {
          name: (question.name ?? ''),
          title: (question.title ?? ''),
          description: (question.description ?? ''),  
          obligatory: (question.obligatory ?? true),
          requiredErrorMsg: (question.requiredErrorMsg ?? ''),        
          optionList: (question.optionList ?? []),
          multiple: (question.multiple ?? null)
        };
        return <DebSelectQuestion {...selectProps} obligatory={selectProps.obligatory} />;
      case 'CHECKBOX':
        const checkboxProps = {
          name: (question.name ?? ''),
          title: (question.title ?? ''),
          description: (question.description ?? ''),  
          obligatory: (question.obligatory ?? true),
          requiredErrorMsg: (question.requiredErrorMsg ?? ''),        
          optionList: (question.optionList ?? []),
          columns: (question.columns ?? 0),
          multiple: (question.multiple ?? null)
        };
        return <DebCheckboxQuestion {...checkboxProps} obligatory={checkboxProps.obligatory} />;
      case 'SHORT':
        const shortProps = {
          name: (question.name ?? ''),
          title: (question.title ?? ''),
          description: (question.description ?? ''),  
          obligatory: (question.obligatory ?? true),
          requiredErrorMsg: (question.requiredErrorMsg ?? '')       
        };
        return <DebSurveyShortQuestion {...shortProps} obligatory={shortProps.obligatory} />;
      case 'LONG':
        const longProps = {
          name: (question.name ?? ''),
          title: (question.title ?? ''),
          description: (question.description ?? ''),  
          obligatory: (question.obligatory ?? true),
          requiredErrorMsg: (question.requiredErrorMsg ?? '')       
        };
        return <DebSurveyLongQuestion {...longProps} obligatory={longProps.obligatory} />;
      default:
        return null;
    }
  };