import {useState} from 'react';
import { useField } from 'formik';
import { useFormikContext } from "formik";
import { FormControl, FormControlLabel, Radio, CardContent, Typography, Tooltip, FormHelperText, Box } from '@mui/material';
import { DebQuestionCard } from '../styledComponents/DebQuestionCard';

function DebMatrixQuestion({ title, obligatory, description, optionList, subQuestionList, requiredErrorMsg, ...props }) {
    const columns = [];
    optionList.forEach((option) => {
      columns.push(option.text);
    });    
    const rows = [];
    subQuestionList.forEach((option) => {
      rows.push(option.title);
    }); 
    

    const [field, meta] = useField({
        ...props,
        defaultValue: null,
      });      
      
    const { values, setFieldValue } = useFormikContext();
    const [selectedOptions, setSelectedOptions] = useState(field.value || null);
    const error = !!meta.touched && meta.error;

    const handleChange = (e) => {
      const { name, value } = e.target;
      const selectedOption = field.value?.[name] === value ? null : value;
      setSelectedOptions(selectedOption);

      setFieldValue(field.name, { ...field.value, [name]: selectedOption });
    };

    const isChecked = (value, row) => {
      const selectedOption = field.value && ((field.value[`${row}`] !== null && field.value[`${row}`] !== undefined) ? Number(field.value[`${row}`]) : null);

      return selectedOption === value;
    };

    const InputComponent = Radio;

    return (
    <DebQuestionCard className="cust-question cust-question-box question-container">
        <span style={{display: 'block'}}>
        <CardContent sx={{ backgroundColor: `${meta.touched && meta.error ? "#ff000017" : "rgba(0, 0, 0, .0)"}`, overflowX: "auto"}}>
            <FormControl component="fieldset" fullWidth>
            <Typography variant="h5" sx={{marginBottom: "1rem"}}>
                {title}
                {obligatory && (
                    <Tooltip
                    title={<Typography>Pregunta Requerida</Typography>}
                    placement="top"
                    arrow>
                    <span style={{ color: "red" }}>&nbsp;&nbsp;*</span>
                    </Tooltip>
                )}
            </Typography>
            <Typography sx={{pb: 2}}>{description}</Typography>  
            <Box display={"grid"} gridTemplateColumns={`auto repeat(${columns.length}, 1fr)`} gap={"1rem"}>
                {/* este item es para dejar un espacio en blanco */}
                <Box></Box>
                {columns.map((column) => (
                <Box key={column}>
                    <Typography textAlign="center">{column}</Typography>
                </Box>
                ))}

                {rows.map((row,i) => (
                    <React.Fragment key={i}>
                    <Box>
                        <Typography>{row}</Typography>
                    </Box>
                    {columns.map((column) => {
                        const option = optionList.find((option) => option.text === column );
                        return (
                        <Box key={option.id} textAlign="center">
                            <FormControlLabel
                                value={option.id}
                                control={
                                    <InputComponent
                                        className='cust-question-input cust-question-matrix'
                                        checked={isChecked(option.id, row)}
                                        onChange={handleChange}
                                        name={`${row}`}
                                        value={option.id}
                                    />
                                }
                                labelPlacement="top"
                                />
                        </Box>
                        );
                    })}
                    </React.Fragment>
                ))}
            </Box>
            <FormHelperText className="cust-question-helper-text" error={error} sx={{fontSize: "1rem", fontWeight: "bold", mt:"1rem"}} >
            {meta.touched && meta.error ? requiredErrorMsg : " "}
            </FormHelperText>
            </FormControl>
        </CardContent>
        </span>
    </DebQuestionCard>
  );
};



export default DebMatrixQuestion;
