import { RequiredErrorMessages } from './requiredErrorMsgs';

export function addRequiredErrorMsg(formFieldWithName) {
    formFieldWithName.steps.forEach((step) => {
        step.questionList.forEach((question) => {
          question.name = question.id.toString();
          question.requiredErrorMsg = null;
          if (question.obligatory) {
            switch (question.questionType) {
              case "EMOJIS":
                question.requiredErrorMsg = RequiredErrorMessages.EMOJIS;
                  break;
              case "LINEAR_SCALE":
              case "STAR":
                question.requiredErrorMsg = RequiredErrorMessages.LINEAR_SCALE;
                  break;
              case "MATRIX":
                question.requiredErrorMsg = RequiredErrorMessages.MATRIX;
                  break;
              case "SELECT":
                question.requiredErrorMsg = RequiredErrorMessages.SELECT;
                  break;
              case "CHECKBOX":
                question.requiredErrorMsg = RequiredErrorMessages.CHECKBOX;
                  break;
              case "SHORT":
                question.requiredErrorMsg = RequiredErrorMessages.SHORT;
                  break;
              case "LONG":
                question.requiredErrorMsg = RequiredErrorMessages.LONG;
                  break;
              default:
                question.requiredErrorMsg = null;
              }
          } else if (question.questionType === 'MATRIX'){
            question.requiredErrorMsg = RequiredErrorMessages.MATRIX;
          }
        });
      });
}