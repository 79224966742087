import { useState } from "react";
import {
    CardContent,
    Typography,
    Tooltip,
    useMediaQuery,
    useTheme,
    FormHelperText,
    IconButton
} from "@mui/material";
import { Box, Stack } from "@mui/system";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import StarIcon from "@mui/icons-material/Star";
import { useField } from "formik";
import { DebQuestionCard } from "../styledComponents/DebQuestionCard";

export default function DebStarQuestion({
    title,
    description,
    obligatory,
    startText,
    endText,
    startValue,
    endValue,
    requiredErrorMsg,
    ...props
}) {
    const [selected, setSelected] = useState();
    const [field, meta, helpers] = useField({ ...props, meta: { ...props.meta } });
    const error = meta.touched && meta.error ? true : false;
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down("sm"));
    const generateButtons = (start, end) => {
        if (!start || !end) return null;
        const num = end - start;
        const dir = num > 0 ? 1 : -1;
        const buttons = [];
        for (let i = 0; i <= Math.abs(num); i++) {
            const buttonNumber = start + i * dir;
            buttons.push(
                <IconButton
                    onClick={() => handleClick(buttonNumber)}
                    key={buttonNumber}>
                    {buttonNumber <= selected ? (
                        <StarIcon sx={{ color: "gold", fontSize: "3rem" }} />
                    ) : (
                        <StarOutlineIcon
                            sx={{ color: "gold", fontSize: "3rem" }}
                        />
                    )}
                </IconButton>
            );
        }
        return buttons;
    };

    const handleClick = (val) => {
        setSelected(val);
        helpers.setValue(val);
    };
    return (
        <DebQuestionCard className="cust-question cust-question-box question-container">
            <span style={{display: 'block'}}>
            <CardContent
                sx={{
                    backgroundColor: `${
                        meta.touched && meta.error
                            ? "#ff000017"
                            : "rgba(0, 0, 0, .0)"
                    }`,
                }}>
                <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
                    {title}
                    {obligatory && (
                        <Tooltip
                            title={<Typography>Pregunta Requerida</Typography>}
                            placement="top"
                            arrow>
                            <span style={{ color: "red" }}>&nbsp;&nbsp;*</span>
                        </Tooltip>
                    )}
                </Typography>
                <Typography>{description}</Typography>
                <Box mt="2rem">
                    <Stack
                        margin={"0 2rem 0 2rem"}
                        direction={"row"}
                        justifyContent={"space-between"}
                        spacing={sm ? 0.5 : 1}>
                        {generateButtons(startValue, endValue)}
                    </Stack>
                    {(startText || endText) && (
                        <Stack
                            margin={"0.5rem 1.5rem 0 1.5rem"}
                            direction={"row"}
                            justifyContent={"space-between"}
                            spacing={2}>
                            <Typography>&nbsp;&nbsp;{startText}</Typography>
                            <Typography>{endText}&nbsp;&nbsp;</Typography>
                        </Stack>
                    )}
                </Box>
                <FormHelperText
                    error={error}
                    sx={{ fontSize: "1rem", fontWeight: "bold", mt: "1rem" }}>
                    {meta.touched && meta.error ? requiredErrorMsg : " "}
                </FormHelperText>
            </CardContent>
            </span>
        </DebQuestionCard>
    );
}
