
import { Outlet } from 'react-router';

function Blank() {
  return (
    <> 
      <Outlet/>
    </>
  );
};

export default Blank;