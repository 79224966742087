import { useFormikContext } from "formik";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  ListItemText,
  OutlinedInput,
  CardContent,
  Typography,
  Tooltip,
  Chip,
  FormHelperText,
} from "@mui/material";
import { DebQuestionCard } from "../styledComponents/DebQuestionCard";
import { Box } from "@mui/system";
import { useField } from "formik";

function DebSelectQuestion({ name, title, optionList, obligatory, description, requiredErrorMsg, multiple, ...props }) {
    const { values, setFieldValue } = useFormikContext();
    const selectedValues = values[name] || (multiple ? [] : '');
    const [field, meta] = useField(name, values);
    const error = meta.touched && meta.error ? true : false;
  
    const handleChange = (event) => {
      const { value } = event.target;
      const newValue = Array.isArray(value) ? value : [value];
      setFieldValue(name, newValue);
    };

    const renderOption = (option) => {
      if (multiple) {
        return (
          <MenuItem key={option.id} value={option.id}>
            <Checkbox checked={selectedValues.indexOf(option.id) > -1} />
            <ListItemText primary={option.text} />
          </MenuItem>
        );
      } else {
        return (
          <MenuItem key={option.id} value={option.id}>
            {option.text}
          </MenuItem>
        );
      }
    };
  
    return (
        <DebQuestionCard className="cust-question cust-question-box question-container">
          <span style={{display: 'block'}}>
            <CardContent sx={{ backgroundColor: `${meta.touched && meta.error ? "#ff000017" : "rgba(0, 0, 0, .0)"}`}}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <Typography variant="h5" sx={{marginBottom: "1rem"}}>
                      {title}
                      {obligatory && (
                          <Tooltip
                          title={<Typography>Pregunta Requerida</Typography>}
                          placement="top"
                          arrow>
                          <span style={{ color: "red" }}>&nbsp;&nbsp;*</span>
                          </Tooltip>
                      )}
                  </Typography>
                  <Typography>{description}</Typography>
                  <Select
                    className="cust-question cust-question-field"
                    multiple={multiple}
                    value={selectedValues}
                    onChange={handleChange}
                    label={title}          
                    input={<OutlinedInput/>}
                    inputProps={{ name: name, className: "cust-question cust-question-input" }}
                    MenuProps={{className: "cust-question cust-question-select-menu"}}
                    renderValue={(selected) => {
                      if (!Array.isArray(selected)) {
                        const option = optionList.find((item) => item.id === selected);
                        return option ? option.text : '';
                      }                   
                      return (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip 
                              className="cust-question cust-question-select-chip"
                              sx={{fontSize: "0.9rem"}} 
                              key={value} 
                              label={optionList.find(item => item.id === value).text} 
                              variant="filled" />
                          ))}
                        </Box>
                      );
                    }}
                    
                    
                  >
                    {optionList.map((option) => renderOption(option))}
                  </Select>
                  <FormHelperText className="cust-question-helper-text" error={error} sx={{fontSize: "1rem", fontWeight: "bold", mt:"1rem"}} >
                    {meta.touched && meta.error ? requiredErrorMsg : " "}
                    </FormHelperText>
                </FormControl>
            </CardContent>
          </span>
      </DebQuestionCard>
    );
  };

export default DebSelectQuestion