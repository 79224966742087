import { useRouteError } from "react-router-dom";
import { SurveyBackground } from "../components/surveyComponents/SurveyBackground";
import pantalla404 from '../img/Pantalla-404.png';
import pantallaErrorInesperado from '../img/Pantalla-error-inesperado.png';

export default function ErrorPage() {
  const error = useRouteError();
  return (
    <SurveyBackground 
        background={error.status === 404 ? pantalla404 : pantallaErrorInesperado}>
    </SurveyBackground>
  );
};
