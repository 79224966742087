import { useState } from 'react';
import { CardContent, FormControl, IconButton, Stack, Tooltip, Typography, FormHelperText, useMediaQuery } from '@mui/material';
import { useField } from 'formik';
import { DebQuestionCard } from '../styledComponents/DebQuestionCard';

function DebEmojiQuestion ({ title, obligatory, description, optionList, requiredErrorMsg, ...props }) {
  const [selected, setSelected] = useState('');
  const [field, meta, helpers] = useField(props);
  const error = meta.touched && meta.error ? true : false;
  let items = 1;
  if (Array.isArray(optionList)) {
    items = optionList.length;
  }
  const lengthPerItem = 100;
  const layoutVertical = useMediaQuery(`(max-width:${items*lengthPerItem}px)`);

  const handleSelect = (value) => {
    setSelected(value);
    helpers.setValue(value);
  };

  return (
      <DebQuestionCard className="cust-question cust-question-box question-container">
          <span style={{ display: "block" }}>
              <CardContent
                  sx={{
                      backgroundColor: `${
                          meta.touched && meta.error
                              ? "#ff000017"
                              : "rgba(0, 0, 0, .0)"
                      }`,
                  }}>
                  <FormControl fullWidth sx={{ mb: 1 }}>
                      <Typography variant="h5" sx={{ marginBottom: "1rem" }}>
                          {title}
                          {obligatory && (
                              <Tooltip
                                  title={
                                      <Typography>
                                          Pregunta Requerida
                                      </Typography>
                                  }
                                  placement="top"
                                  arrow>
                                  <span style={{ color: "red" }}>
                                      &nbsp;&nbsp;*
                                  </span>
                              </Tooltip>
                          )}
                      </Typography>
                      <Typography sx={{mb: 2}}>{description}</Typography>
                      <Stack
                          direction={layoutVertical ? "column" : "row"}
                          spacing={2}
                          alignItems={layoutVertical ? "center": "flex-start"}
                          justifyContent={"space-evenly"}>
                          {optionList.map((option, i) => (
                              <Stack key={i} direction={"column"} alignItems={"center"}>
                                  <IconButton
                                      className="emoticon-option"
                                      sx={{ width:{xs: "2em", sm: "3em"}, height: {xs: "2em", sm: "3em"}, backgroundColor: selected === option.id ? "#00000011" : ""}}
                                      color={
                                          selected === option.id
                                              ? "primary"
                                              : "default"
                                      }
                                      onClick={() => handleSelect(option.id)}>
                                      <Typography sx={{ fontSize: {xs: "2rem", sm: "3rem"} }}>
                                          {option.image}
                                      </Typography>
                                  </IconButton>
                                  <Typography
                                      className="cust-question"
                                      sx={{ marginTop: "1rem", fontWeight: selected === option.id
                                      ? "bold"
                                      : "normal" }}
                                      >
                                      {option.text}
                                  </Typography>
                              </Stack>
                          ))}
                      </Stack>
                      <FormHelperText
                          className="cust-question-helper-text"
                          error={error}
                          sx={{ fontSize: "1rem", fontWeight: "bold" }}>
                          {meta.touched && meta.error ? requiredErrorMsg : " "}
                      </FormHelperText>
                  </FormControl>
              </CardContent>
          </span>
      </DebQuestionCard>
  );
};

export default DebEmojiQuestion;

