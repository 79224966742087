
import Typography from '@mui/material/Typography';
import { Box, Container, Divider, Stack, useMediaQuery, useTheme } from '@mui/material';

function DebContainer({logo, title, children}) {
    const theme = useTheme();
    const sm = useMediaQuery(theme.breakpoints.down('sm'));
    const lg = useMediaQuery(theme.breakpoints.down('lg'));

    let logoURL;
    if (logo && logo.startsWith("/")){
        logoURL = logo.substring(1);
    } else {
        logoURL = logo;
    }


  return (
    <Container disableGutters={sm}>
      <Box className='cust-survey-container survey-body' sx={{ height: 'fit-content', borderRadius: '0.7rem', overflow: "hidden" }} >
          <Stack
            className='survey-header'
            direction={lg ? "column": "row"} 
            sx={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                margin: 0,
                padding: "1rem"
                }} 
            margin="1rem">
              { logoURL && <img src={logoURL} style={{ maxWidth: "20rem" }} alt="logo" /> }
            <div className='title-container'>
              <Typography 
                gutterBottom 
                variant="h4" 
                component="h1" 
                textAlign="center">
                {title}
              </Typography>
            </div>
          </Stack>
          <Divider/>
          {children}
        </Box>
    </Container>
  );
};

export default DebContainer