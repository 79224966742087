
export function addInitialValues(initialValues, steps) {
  steps.forEach((step) => {
    
    step.questionList.forEach((question) => {
        switch (question.questionType) {
          case "EMOJIS":
          case "LINEAR_SCALE":
          case "STAR":
            initialValues[question.name] = null ;
              break;
          case "MATRIX":
            const rows = [];
            question.subQuestionList.forEach((subQuestion) => {
              rows.push(subQuestion.title);
            });
            let rowsInitial = {};
            for (const rowName of rows) {
              rowsInitial[rowName] = null;
            }
            initialValues[question.name] =  rowsInitial;
              break;
          case "SELECT":
            initialValues[question.name] = question.multiple ? [] : '';
            break;
          case "CHECKBOX":
            initialValues[question.name] = [];
              break;
          case "SHORT":
          case "LONG":
            initialValues[question.name] = '';
              break;
          default:
            break;
        }
    });
  });
  return initialValues;
}