
export const API_TEMPLATES = "templates";
export const API_TEMPLATES_BY_COMPANY = "templates/company";
export const API_TEMPLATES_BY_SURVEY = "templates/survey";
export const API_SURVEYS = "surveys";
export const API_SURVEYS_BY_COMPANY = "surveys/company";
export const API_SURVEYS_STATUS = "status";
export const API_QUESTIONS = "questions";
export const API_QUESTIONS_BY_SURVEY = "questions/survey";
export const API_ANSWERS = "answers/survey";
export const API_ANSWERS_BY_QUESTION = "answers/questions";
export const API_UTILS_TIME = "api/time";

