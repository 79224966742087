import { Box, Button, CardContent, Typography } from '@mui/material';
import { DebQuestionCard } from '../components/styledComponents/DebQuestionCard';

function SurveySuccess({ redirectUrl }) {

    const handleRedirect = () => {
        const HTTPS = 'https://';
        let url = /^https?:\/\//.test(redirectUrl)
            ? redirectUrl
            : 'https://' + redirectUrl;

        window.location.replace(new URL(url));
    };

  return (
    <>
      <Box sx={{  height: "2rem" }} />
      <Box sx={{display: "flex", justifyContent: "center",  alignItems: "center"}} >
        <DebQuestionCard>
          <CardContent>
            <Box sx={{  height: "2rem" }} />
            <Typography variant="h5" sx={{display: "flex", flexDirection: "column", justifyContent: "center",  alignItems: "center"}} gutterBottom>
            Gracias por contestar la encuesta
            <Box sx={{ height: "2rem" }} />
                {redirectUrl && (
                    <Button onClick={handleRedirect} variant='contained' color='primary'>
                        Aceptar
                    </Button>
                )}
            </Typography>
            <Box sx={{ height: "2rem" }} />
          </CardContent>
        </DebQuestionCard>  
      </Box>
      <Box sx={{  height: "2rem" }} />
    </>
  );
}

export default SurveySuccess;
