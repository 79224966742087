
import { useFormikContext } from "formik";
import { useField } from 'formik';
import {
  FormControl,
  FormControlLabel,
  Checkbox,
  Grid,
  CardContent,
  Typography,
  Tooltip,
  FormHelperText,
  Box,
} from "@mui/material";
import { DebQuestionCard } from "../styledComponents/DebQuestionCard";

function DebCheckboxQuestion({ name, title, optionList, obligatory, description, requiredErrorMsg, multiple, ...restProps }) {
    const { values, setFieldValue } = useFormikContext();
    const [field, meta] = useField(name,values);
    const error = meta.touched && meta.error ? true : false;
  
    const handleChange = (event) => {
      const { checked, value } = event.target;
      let newValues = [...values[name]];
      if (checked) {
          if (multiple) {
              newValues.push(value);
          } else {
              newValues = [value];
          }
      } else {
        newValues = newValues.filter((val) => val !== value);
      }
      setFieldValue(name, newValues);
    };
   
    //Calculo de columnas para el renderizado
    let columns = 1;
    for (let i = 2; i <= 10; i++) {
      if (optionList.length % i === 0) {
        columns = i; 
        break;         
      }
      
    } ;
    const columnSize = 12 / columns;

    return (
      
        <DebQuestionCard className="cust-question cust-question-box question-container">
          <span style={{display: 'block'}}>
            <CardContent sx={{ backgroundColor: `${meta.touched && meta.error ? "#ff000017" : "rgba(0, 0, 0, .0)"}`}}>
                <FormControl
                    component="fieldset"
                    fullWidth
                >
                    <Grid container spacing={2}>
                    <Grid item xs={12}>
                    <Typography variant="h5" sx={{marginBottom: "1rem"}}>
                    {title}
                    {obligatory && (
                        <Tooltip
                        title={<Typography>Pregunta Requerida</Typography>}
                        placement="top"
                        arrow>
                        <span style={{ color: "red" }}>&nbsp;&nbsp;*</span>
                        </Tooltip>
                    )}
                    </Typography>
                    <Typography>{description}</Typography>
                    </Grid>
                    {optionList.map((option, index) => (
                        <Grid item xs={12} sm={columnSize} key={index}>
                        <FormControlLabel
                            control={
                            <Checkbox
                              className="cust-question-input cust-question-input-checkbox"
                              sx={{marginLeft: "2rem"}}
                                checked={(values[name] ? values[name].map(Number).includes(option.id) : false )}
                                onChange={handleChange}
                                value={option.id}
                            />
                            }
                            label={option.text}
                        />                        
                        </Grid>
                    ))}
                    </Grid>
                    <FormHelperText className="cust-question-helper-text" error={error} sx={{fontSize: "1rem", fontWeight: "bold", mt:"1rem"}} >
                    {meta.touched && meta.error ? requiredErrorMsg : " "}
                    </FormHelperText>
                </FormControl>
            </CardContent>
          </span>
      </DebQuestionCard>
    );
  };


export default DebCheckboxQuestion;


