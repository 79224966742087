import { SnackbarProvider } from 'notistack';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import "./App.css";
import ErrorPage from "./routes/ErrorPage";
import SurveyCreator, { surveyCreatorLoader, surveyCreatorPreviewLoader } from "./components/surveyComponents/SurveyCreator";
import Blank from './routes/Blank';
import NotFoundPage from './routes/NotFoundPage';


const theme = createTheme({
    typography: {
        fontFamily: "Montserrat",
    },
    palette: {
        primary: {
          main: "#888899",
        },
        secondary: {
          main: "#555555",
        },
      },
});

export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/" errorElement={<ErrorPage />} element={<Blank />}>
            <Route path="/" element={<NotFoundPage />} />
            <Route
                path="surveys/status/:surveyQueueId"
                loader={surveyCreatorLoader}
                element={<SurveyCreator />}
            />
            <Route
                path="surveys/preview/:surveyId"
                loader={surveyCreatorPreviewLoader}
                element={<SurveyCreator />}
            />
        </Route>
    )
);

function App() {  

    return (
        <>
            <ThemeProvider theme={theme}>
              <SnackbarProvider 
                maxSnack={3}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}>
                 <RouterProvider router={router} />
              </SnackbarProvider>
            </ThemeProvider>
        </>
    );
}

export default App;