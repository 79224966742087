
export function makeAnswerList(values, formFieldWithName) {
  const questionAnswers = [];
  formFieldWithName.steps.forEach((step) => {
      step.questionList.forEach((question) => {
        switch(question.questionType) {
          case 'EMOJIS':
            if (values[question.name]) {
              questionAnswers.push({
                questionId: question.id,
                answerType: 'OPTION',
                selectedOptionList: [
                  {
                    "optionId": values[question.name]
                  }                  
                ],
                answered: true
              });
            } else {
              questionAnswers.push({
                questionId: question.id,
                answerType: 'OPTION',
                selectedOptionList: [],
                answered: false
              })
            }
            break;
          case 'LINEAR_SCALE':
          case 'STAR':
            if (values[question.name]) {
              questionAnswers.push({
                questionId: question.id,
                answerType: 'NUMBER',
                value: values[question.name],
                answered: true
              });
            } else {
              questionAnswers.push({
                questionId: question.id,
                answerType: 'NUMBER',
                answered: false
              })
            }
            break;
          case 'CHECKBOX':
            let checkboxOptions = [];
            if (values[question.name] && values[question.name].length > 0) {
              for (let i = 0; i < values[question.name].length; i++) {
                checkboxOptions.push({
                  "optionId": values[question.name][i]
                });
              }
              questionAnswers.push({
                questionId: question.id,
                answerType: 'OPTION',
                selectedOptionList: checkboxOptions,
                answered: true
              });
            } else {
              questionAnswers.push({
                questionId: question.id,
                answerType: 'OPTION',
                selectedOptionList: [],
                answered: false
              });
            }
            break;
          case 'MATRIX':
            let matrixOptions = [];
            for (let subQuestionName in values[question.name]) {
              for (let subQuestion of question.subQuestionList) {
                if (subQuestionName === subQuestion.title) {
                  if (values[question.name][subQuestionName]){
                    matrixOptions.push({
                      "questionId": subQuestion.id,
                      "answerType": 'OPTION',
                      "selectedOptionList": [
                        {
                          "optionId": Number(values[question.name][subQuestionName])
                        }
                      ]
                    });
                  };
                }
              }
            };
            if (matrixOptions.length > 0) {
              questionAnswers.push({
                questionId: question.id,
                answerType: 'MATRIX',
                matrix: matrixOptions,
                answered: true
              });
            } else {
              questionAnswers.push({
                questionId: question.id,
                answerType: 'MATRIX',
                matrix: [],
                answered: false
              });
            }
            break;
          case 'SELECT':
            let selectOptions = [];
            for (let i = 0; i < values[question.name].length; i++) {
              selectOptions.push({
                "optionId": values[question.name][i]
              });
            };
            if (selectOptions.length > 0) {
              questionAnswers.push({
                questionId: question.id,
                answerType: 'OPTION',
                selectedOptionList: selectOptions,
                answered: true
              });
            } else {
              questionAnswers.push({
                questionId: question.id,
                answerType: 'OPTION',
                selectedOptionList: [],
                answered: false
              });
            }
            break;
          case 'LONG':
            if (values[question.name]) {
              questionAnswers.push({
                questionId: question.id,
                answerType: 'LONG',
                text: values[question.name],
                answered: true
              });
            } else {
              questionAnswers.push({
                questionId: question.id,
                answerType: 'LONG',
                answered: false
              })
            }
            break;
          case 'SHORT':
            if (values[question.name]) {
              questionAnswers.push({
                questionId: question.id,
                answerType: 'SHORT',
                text: values[question.name],
                answered: true
              });
            } else {
              questionAnswers.push({
                questionId: question.id,
                answerType: 'SHORT',
                answered: false
              })
            }
            break;
          default:
            if (values[question.name]) {
              questionAnswers.push({
                questionId: question.id,
                answerType: 'OTHER',
                value: values[question.name]
              });
            };
            break;
        }
        
      });
    });

  return questionAnswers;
}