import api from "./api";
import { API_ANSWERS, API_ANSWERS_BY_QUESTION } from "../constants/urls";

/**
 * Servicio para buscar, crear, modificar y eliminar answers
 * @param {object} answer 
 * @param {string} answer.questionId
 * @param {string} answer.answerType
 * @param {Object[]} answer.matrix
 * @param {number} answer.matrix.questionId
 * @param {string} answer.matrix.answerType
 * @param {Object[]} answer.matrix.selectedOptionList
 * @param {number} answer.matrix.selectedOptionList.optionId
 * @param {string} answer.text
 * @param {number} answer.value
 * @param {Object[]} answer.selectedOptionList
 * @param {number} answer.selectedOptionList.optionId
 * @returns
 */


export const answer = {    
    getById: async (id) => (await api.get(`${API_ANSWERS}/${id}`)).data,
    getByQuestionId: async  (id) => (await api.get(`${API_ANSWERS_BY_QUESTION}/${id}`)).data,
    answerSurvey: async (surveyAnswer) => (await api.post(API_ANSWERS, surveyAnswer)).data,
    update: async (answer) => (await api.put(`${API_ANSWERS}/${answer.id}`, answer)).data,
    delete: async (id) => (await api.delete(`${API_ANSWERS}/${id}`)).data
};