import { styled } from "@mui/material";

const SurveyBackgroundContainer = styled("div")(({ theme }) => ({
    minHeight: "100vh",
    height: "fit-content",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
}));

export function SurveyBackground({ background, customCss, children }) {
    return (
        <SurveyBackgroundContainer
            className="cust-background survey-page"
            sx={{
                backgroundImage: `url(${background})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
            }}>
            <style>{customCss}</style>
            {children}
        </SurveyBackgroundContainer>
    );
}
