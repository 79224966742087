import { Box, ThemeProvider, createTheme } from "@mui/material";
import DebContainer from "./DebContainer";
import { survey } from "../../services/surveys";
import { enqueueSnackbar } from "notistack";
import {
    STATUS_ANSWERED,
    STATUS_DISABLED,
    STATUS_EXPIRED,
} from "../../constants/surveyStatus";
import { useLoaderData } from "react-router";
import SurveyDisabled from "../../routes/SurveyDisabled";
import SurveyExpired from "../../routes/SurveyExpired";
import SurveyAnswered from "../../routes/SurveyAnswered";
import Survey from "./Survey";
import { SurveyBackground } from "./SurveyBackground";

export async function surveyCreatorLoader({ params }) {
    try {
        const res = await survey.getStatus(params.surveyQueueId);
        return generateDataForSurvey(res, params.surveyQueueId, false);
    } catch (error) {
        if (error.response.status >= 400 && error.response.status < 500)
        throw new Response("Not Found", { status: 404 });
        throw error;
    }
}

export async function surveyCreatorPreviewLoader({ params }) {
    try {
        const res = surveyGetByIdToGetStatusResponse(
            await survey.getById(params.surveyId, true)
        );
        return generateDataForSurvey(res, params.surveyQueueId, true);
    } catch (error) {
        if (error.response.status >= 400 && error.response.status < 500)
        throw new Response("Not Found", { status: 404 });
        throw error;
    }
}

function SurveyCreator() {
    const { surveyMedia, status, surveyQueueId, survey, preview } = useLoaderData();
    let theme = getTheme(survey?.customization?.muiThemeJson)
    return (
        <ThemeProvider theme={theme}>
            <SurveyBackground
                background={surveyMedia.background}
                customCss={survey?.customization?.css}>
                <Box sx={{ height: "2rem" }} />
                <DebContainer logo={surveyMedia.logo} title={surveyMedia.title}>
                    {componentByStatus(status,{ backForm: survey, surveyQueueId }, preview)}
                </DebContainer>
                <Box sx={{ height: "2rem" }} />
            </SurveyBackground>
        </ThemeProvider>
    );
}
export default SurveyCreator;

const componentByStatus = (status, props, preview) => {
    switch (status) {
        case STATUS_DISABLED:
            return <SurveyDisabled />;
        case STATUS_EXPIRED:
            return <SurveyExpired />;
        case STATUS_ANSWERED:
            return <SurveyAnswered />;
        default:
            return preview ? (
                <Survey preview {...props} />
            ) : (
                <Survey {...props} />
            );
    }
};

function getTheme(muiThemeJson) {

    let parsedTheme;
    if (muiThemeJson) {
        try {
            parsedTheme = JSON.parse(muiThemeJson);
        } catch (err) {
            console.error("Error parsing theme JSON" + muiThemeJson, err);
            return createTheme();
        }
    }

    if (!parsedTheme){
        return createTheme();
    }

    if (parsedTheme.palette?.primary?.main === "") {
        parsedTheme.palette.primary=null;
    }
    if (parsedTheme.palette?.secondary?.main === "") {
        parsedTheme.palette.secondary=null;
    }
    return createTheme(parsedTheme);
}

function surveyGetByIdToGetStatusResponse(data) {
    return {
        name: data.name,
        customization: data.customization,
        survey: { ...data },
    };
}

function generateDataForSurvey(res, surveyQueueId, preview) {
    const surveyMedia = {
        title: res.name,
        background: res.customization?.backgroundImg,
        logo: res.customization?.logoUrl,
    };
    return {
        surveyQueueId,
        survey: res.survey,
        surveyMedia,
        status: res.status,
        preview
    };
}