import { Box, CardContent, Typography } from "@mui/material";
import { useEffect } from "react";
import { DebQuestionCard } from "../components/styledComponents/DebQuestionCard";
import { enqueueSnackbar } from "notistack";


export default function SurveyAnswered() {
    useEffect(() => {
        enqueueSnackbar("La encuesta ya fue contestada", {
            preventDuplicate: true,
            variant: "success",
        });
    }, []);

    return (
    <>
        <Box sx={{  height: "2rem" }} />
        <Box sx={{display: "flex", justifyContent: "center",  alignItems: "center"}} >
            <DebQuestionCard>
                <CardContent>
                    <Box sx={{  height: "2rem" }} />
                        <Typography variant="h4" sx={{display: "flex", justifyContent: "center",  alignItems: "center"}} gutterBottom>
                            Usted ya contestó esta encuesta
                        </Typography>
                        <Typography variant="h6" sx={{display: "flex", justifyContent: "center",  alignItems: "center"}} gutterBottom>
                            Muchas gracias
                        </Typography>
                    <Box sx={{  height: "2rem" }} />
                </CardContent>
            </DebQuestionCard>  
        </Box>
        <Box sx={{  height: "2rem" }} />
    </>
    );
};